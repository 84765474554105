import React, { createContext, useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useOrderForm, useOrderItems } from '@vtex/gatsby-theme-store'
import { updateOrderFormRepresentantId } from 'src/services/orderForm'
import { useSession } from '@vtex/store-sdk'
import { getCookie, deleteCookie } from 'src/utils/cookies'

interface RepresentativeType {
  aprovado: boolean
  cidade: string | null
  email: string | null
  emailEspaco: string | null
  estado: string | null
  facebook: string | null
  fotoPerfil: string | null
  instagram: string | null
  nome: string | null
  nomeRevendedora: string | null
  telefone: string | null
  textoSaudacao: string | null
  url: string | null
  whatsapp: string | null
  utmiPc: string | null
}

interface RepresentativeContextType {
  isRepresentativeSelected: boolean
  representative: RepresentativeType
  isSelectedRepresentativePopupOpen: boolean
  isSearchRepresentativePopupOpen: boolean
  searchRepresentative: (id?: string) => Promise<void | null>
  buyWithoutRepresentative: () => void
  toggleSelectedRepresentativePopup: (value: boolean) => void
  toggleSearchRepresentativePopup: (value: boolean) => void
  setRepresentativeInDevelopment: () => Promise<void>
}

export const RepresentativeContext = createContext(
  {} as RepresentativeContextType
)

const initialState = {
  aprovado: true,
  cidade: null,
  email: null,
  emailEspaco: null,
  estado: null,
  facebook: null,
  fotoPerfil: null,
  instagram: null,
  nome: null,
  nomeRevendedora: null,
  telefone: null,
  textoSaudacao: null,
  url: null,
  whatsapp: null,
  utmiPc: null,
}

export const RepresentativeContextProvider: FC<{ search: string }> = ({
  children,
  search,
}) => {
  const [representative, setRepresentative] =
    useState<RepresentativeType>(initialState)

  const [
    isSelectedRepresentativePopupOpen,
    setIsSelectedRepresentativePopupOpen,
  ] = useState(false)

  const [isSearchRepresentativePopupOpen, setIsSearchRepresentativePopupOpen] =
    useState(false)

  const toggleSelectedRepresentativePopup = (value: boolean) => {
    setIsSelectedRepresentativePopupOpen(value)
  }

  const toggleSearchRepresentativePopup = (value: boolean) => {
    setIsSearchRepresentativePopupOpen(value)
  }

  const { orderForm } = useOrderForm()
  const { setSession, ...session } = useSession()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { addItems } = useOrderItems()

  const hasResetedMarketingData = getCookie('resetedMarketingData')

  const isRepresentativeSelected = representative !== initialState

  const searchRepresentative = useCallback(
    async (representativeId?: string) => {
      const params = new URLSearchParams(search)
      const id = representativeId ?? params.get('utmi_pc')

      if (!executeRecaptcha || !id) {
        return null
      }

      const tokenRecaptcha = await executeRecaptcha('representante')

      const [representativeData]: RepresentativeType[] = await fetch(
        'https://conecta2.avon.com.br/api/findregasc/search',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json, text/plain, */*',
            'Content-type': 'application/json',
          },
          body: JSON.stringify({
            regasc: id,
            tokenRecaptcha,
          }),
        }
      ).then((res) => res.json())

      if (!representativeData) {
        localStorage.removeItem('representative')

        params.delete('utmi_pc')

        return setRepresentative(initialState)
      }

      toggleSearchRepresentativePopup(false)

      const rep = {
        ...representativeData,
        utmiPc: id,
      }

      deleteCookie('isUserColab')
      deleteCookie('isColaborador')
      localStorage.setItem('representative', JSON.stringify(rep))
      setRepresentative(rep)

      setSession({ ...session, channel: '2' })

      addItems([{ id: '0', quantity: 1, seller: '1' }], {
        salesChannel: '2',
      })

      const representativeInLocalStorage = localStorage.getItem(
        'representative'
      )
        ? JSON.parse(localStorage.getItem('representative') as string)
        : null

      params.set(
        'utmi_pc',
        representativeInLocalStorage.utmiPc ?? representative.utmiPc
      )

      window.history.pushState(
        null,
        '',
        `
            ${window.location.pathname}?${params?.toString() ?? ''}
          `
      )

      await updateOrderFormRepresentantId(
        orderForm.id,
        orderForm.marketingData,
        id
      )

      const utmSource = params.get('utm_source')

      fetch(
        `https://conecta.avon.com.br/middlewares/monitora_visitas.php?revendID=${id}&source=${utmSource}`,
        {
          method: 'POST',
        }
      )
    },
    [
      addItems,
      executeRecaptcha,
      orderForm.id,
      orderForm.marketingData,
      representative.utmiPc,
      search,
      session,
      setSession,
    ]
  )

  const buyWithoutRepresentative = async () => {
    const params = new URLSearchParams()

    params.delete('utmi_pc')
    localStorage.removeItem('representative')

    window.history.pushState(
      null,
      '',
      `${window.location.pathname}?${params.toString()}`
    )

    await updateOrderFormRepresentantId(
      orderForm.id,
      orderForm.marketingData,
      '0'
    )

    setSession({ ...session, channel: '1' })
    addItems([{ id: '0', quantity: 1, seller: '1' }], {
      salesChannel: '1',
    })

    setRepresentative(initialState)
  }

  useEffect(() => {
    const params = new URLSearchParams(search)
    const utmi = params.get('utmi_pc')
    const representativeInLocalStorage = localStorage.getItem('representative')
      ? JSON.parse(localStorage.getItem('representative') as string)
      : null

    if (isRepresentativeSelected && utmi === '0') {
      buyWithoutRepresentative()
    }

    if (
      representativeInLocalStorage &&
      representativeInLocalStorage !== representative &&
      representative.utmiPc === utmi
    ) {
      return setRepresentative(representativeInLocalStorage)
    }

    if (
      ((search.includes('utmi_pc') && !representativeInLocalStorage) ||
        (search.includes('utmi_pc') && representative.utmiPc !== utmi)) &&
      orderForm.id !== 'default-order-form' &&
      utmi !== '0'
    ) {
      searchRepresentative()
    }

    if (
      !utmi &&
      (representativeInLocalStorage ?? representative) &&
      (representativeInLocalStorage?.utmiPc ?? representative?.utmiPc)
    ) {
      params.set(
        'utmi_pc',
        representativeInLocalStorage.utmiPc ?? representative.utmiPc
      )

      const paramsPath = `?${params.toString()}`
      const relativePath = `
        ${window.location.pathname}${paramsPath || ''}
      `

      return window.history.pushState(null, '', relativePath)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executeRecaptcha, search, orderForm.id])

  useEffect(() => {
    if (
      representative.utmiPc &&
      hasResetedMarketingData &&
      orderForm.id !== 'default-order-form'
    ) {
      updateOrderFormRepresentantId(
        orderForm.id,
        orderForm.marketingData,
        representative.utmiPc
      )

      deleteCookie('resetedMarketingData')
    }
  }, [
    hasResetedMarketingData,
    orderForm.id,
    orderForm.marketingData,
    representative.utmiPc,
  ])

  // This function serves to set the representative only on localhost
  // where we don't have access to the representative API
  const setRepresentativeInDevelopment = async () => {
    const representativeTest = {
      aprovado: true,
      cidade: 'SÃO PAULO',
      email: 'representanteTeste@localtest.com',
      emailEspaco: 'representanteTeste@localtest.com',
      estado: 'SP',
      facebook: 'representanteTeste@localtest.com',
      fotoPerfil: '',
      instagram: null,
      nome: 'Teste',
      nomeRevendedora: 'Teste',
      telefone: '11999999999',
      textoSaudacao: 'Essa é uma representante fake para realizar testes',
      url: 'representanteTeste',
      utmiPc: '111111111119',
      whatsapp: '11999999999',
    }

    toggleSearchRepresentativePopup(false)

    localStorage.setItem('representative', JSON.stringify(representativeTest))

    setSession({ ...session, channel: '2' })

    addItems([{ id: '0', quantity: 1, seller: '1' }], {
      salesChannel: '2',
    })

    await updateOrderFormRepresentantId(
      orderForm.id,
      orderForm.marketingData,
      '0'
    )

    const params = new URLSearchParams(search)
    const representativeInLocalStorage = localStorage.getItem('representative')
      ? JSON.parse(localStorage.getItem('representative') as string)
      : null

    params.set(
      'utmi_pc',
      representativeInLocalStorage.utmiPc ?? representative.utmiPc
    )

    window.history.pushState(
      null,
      '',
      `
    ${window.location.pathname}?${params?.toString() ?? ''}
  `
    )

    setRepresentative(representativeTest)
  }

  return (
    <RepresentativeContext.Provider
      value={{
        representative,
        searchRepresentative,
        buyWithoutRepresentative,
        isRepresentativeSelected,
        isSelectedRepresentativePopupOpen,
        isSearchRepresentativePopupOpen,
        toggleSelectedRepresentativePopup,
        toggleSearchRepresentativePopup,
        setRepresentativeInDevelopment,
      }}
    >
      {children}
    </RepresentativeContext.Provider>
  )
}
