import type { FC } from 'react'
import React, { useMemo, useState, createContext } from 'react'

export interface IContext {
  isNotifyMeModalOpen: boolean
  setIsNotifyMeModalOpen: (value: boolean) => void
  notifyMeSkuSelected: string
  setNotifyMeSkuSelected: (value: string) => void
}

export const NotifyMeModalContext = createContext<IContext | undefined>(
  undefined
)

export const NotifyMeModalProvider: FC = ({ children }) => {
  const [isNotifyMeModalOpen, setIsNotifyMeModalOpen] = useState<boolean>(false)
  const [notifyMeSkuSelected, setNotifyMeSkuSelected] = useState<string>('')

  const value = useMemo(
    () => ({
      isNotifyMeModalOpen,
      notifyMeSkuSelected,
      setIsNotifyMeModalOpen: (val: boolean) => {
        setIsNotifyMeModalOpen(val)
      },
      setNotifyMeSkuSelected: (val: string) => {
        setNotifyMeSkuSelected(val)
      },
    }),
    [isNotifyMeModalOpen, notifyMeSkuSelected]
  )

  return (
    <NotifyMeModalContext.Provider value={value}>
      {children}
    </NotifyMeModalContext.Provider>
  )
}
