import type { FC } from 'react'
import React, {
  useMemo,
  useState,
  useCallback,
  createContext,
  useEffect,
} from 'react'
import { useOrderForm } from '@vtex/gatsby-theme-store'
import { useSession } from '@vtex/store-sdk'
import { shippingCalculate } from 'src/services/shippingCalculate'

export interface IContext {
  freightChanged: boolean
  showCalculateShipping: boolean
  setShowCalculateShipping: (value: boolean) => void
  calculateShippingResponse: string | null
  setCalculateShippingResponse: (value: string | null) => void
  handleCalculateShippingSearch: (postalCode: string) => void
}

export const ShippingCalculateContext = createContext<IContext | undefined>(
  undefined
)

export const ShippingCalculateProvider: FC = ({ children }) => {
  const [freightChanged, setFreightChanged] = useState<boolean>(false)
  const [showCalculateShipping, setShowCalculateShipping] =
    useState<boolean>(false)

  const [calculateShippingResponse, setCalculateShippingResponse] = useState<
    string | null
  >(null)

  const { orderForm } = useOrderForm()
  const { channel } = useSession()

  const postalCodeStorage = localStorage.getItem('freightPostalCode')

  const handleCalculateShippingSearch = useCallback(
    async (postalCode: string) => {
      const response = await shippingCalculate(postalCode, orderForm, channel)

      const prices = response?.reduce(
        (acc: number, price: { slas: Array<{ price: number }> }) =>
          acc + price.slas[0].price,
        0
      )

      const freightPrice = prices / 100

      setFreightChanged(calculateShippingResponse !== String(freightPrice))

      return setTimeout(
        () => setCalculateShippingResponse(String(freightPrice)),
        3000
      )
    },
    [calculateShippingResponse, channel, orderForm]
  )

  useEffect(() => {
    if (orderForm.items && postalCodeStorage) {
      handleCalculateShippingSearch(postalCodeStorage)
    }
  }, [orderForm.items, handleCalculateShippingSearch, postalCodeStorage])

  const value = useMemo(
    () => ({
      calculateShippingResponse,
      freightChanged,
      setCalculateShippingResponse: (val: string | null) => {
        setCalculateShippingResponse(val)
      },
      showCalculateShipping,
      setShowCalculateShipping: (val: boolean) => {
        setShowCalculateShipping(val)
      },
      handleCalculateShippingSearch: (val: string) => {
        handleCalculateShippingSearch(val)
      },
    }),
    [
      calculateShippingResponse,
      freightChanged,
      showCalculateShipping,
      handleCalculateShippingSearch,
    ]
  )

  return (
    <ShippingCalculateContext.Provider value={value}>
      {children}
    </ShippingCalculateContext.Provider>
  )
}
