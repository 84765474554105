import { minicartI18nEN, authProvidersI18nEN } from '@vtex/store-ui'

export default {
  ...minicartI18nEN,

  ...authProvidersI18nEN,
  'login.button.greeting': 'Hello, Client',
  'login.button.action': 'Sign in',
  'login.button.actionMobile': 'Sign in or register',
  'login.page.emailAndPassword.signUpForm.title': 'Please enter your email',

  'shelf.title.0': 'New Offers',

  'social.share': 'Share',

  'offer.soldout': 'Product unavailable',
  'offer.product-unavailable': 'Product Unavailable',
  'offer.units-left': '{quantity} units left!',
  'offer.installments.bold': 'Up to',
  'offer.installments': ' {numberOfInstallments}X interest-free',
  'offer.discount': ' Save {price}',
  'offer.product.installments': 'In {numberOfInstallments}x of {value}',
  'offer.product.listprice': 'From {listPrice}',

  'productDetails.reference': 'Reference',
  'product-not-found': 'Product not found',
  'error-generic': 'Error',

  'buy-button.add-to-cart': 'ADD TO CART',
  'buy-button.add-to-cart.bbb': 'BUY',

  'facets.filters': 'Filters',

  loading: 'Loading...',

  'notification-bar.sale': 'SELECTED ITEMS ON SALE! CHECK IT OUT!',

  'product.quantity.title': 'Quantity',

  'searchControls.totalCount':
    'We have {count} {count,plural,=0{ item for you}one{ item for you}other{ items for you}}',
  'searchControls.filters': 'FILTERS ',
  'searchControls.select.scoreDesc': 'Relevance',
  'searchControls.select.priceDesc': 'Price: High to Low',
  'searchControls.select.priceAsc': 'Price: Low to High',
  'searchControls.select.topSaleDesc': 'Sales',
  'searchControls.select.reviewRateDesc': 'Reviews',
  'searchControls.select.nameDesc': 'Name, descending',
  'searchControls.select.nameAsc': 'Name, ascending',
  'searchControls.select.releaseDateDesc': 'Release date',
  'searchControls.select.bestDiscountDesc': 'Discount',

  'error.title': 'Ops, an error has occured!',
  'error.action': 'Please, go back and try again',
  'error.action.button': 'Go Back',
  'error.detail': 'Error: {errorId}',

  'advantageBar.label1.regular': 'We ship to ',
  'advantageBar.label1.bold': 'all over Brazil',
  'advantageBar.label2':
    'In up to 6 installments without interest - Minimum installment R$10.00',

  'departmentMenu.label.item0': 'News',
  'departmentMenu.label.item1': 'Kits and Gifts',
  'departmentMenu.label.item2': 'Makeup',
  'departmentMenu.label.item3': 'Face',
  'departmentMenu.label.item4': 'Bath and Body',
  'departmentMenu.label.item5': 'Fragrance',

  'submenu.footermenu1.label': 'Avon',
  'submenu.footermenu1.item1': 'About us',
  'submenu.footermenu1.item2': 'Careers',
  'submenu.footermenu1.item3': 'Ethics & Compliance',
  'submenu.footermenu1.item4': 'Privacy Policy',
  'submenu.footermenu1.item5': 'Press',

  'submenu.footermenu2.label': 'Be a Representative',
  'submenu.footermenu2.item1': 'I already represent',
  'submenu.footermenu2.item2': 'I wanna be a representantive',
  'submenu.footermenu2.item3': 'Find a representative',
  'submenu.footermenu2.item4': 'Digital Magazine',

  'submenu.footermenu3.label': 'Avon Institute',
  'submenu.footermenu3.item1': 'About',
  'submenu.footermenu3.item2': 'Breast cancer',
  'submenu.footermenu3.item3': 'Violence Against Girls and Women',

  'submenu.footermenu4.label': 'Support',
  'submenu.footermenu4.item1': 'Customer Service',
  'submenu.footermenu4.item2': 'F.A.Q.',
  'submenu.footermenu4.item3': 'Regulation',
  'submenu.footermenu4.item4': 'Exchange and Return',

  'suggestions.autocomplete.title': 'Search suggestions',
  'suggestions.autocomplete.notFound': 'No suggestions found',
  'suggestions.products.title': 'Suggested products',
  'suggestions.products.total': 'See all {total} items',
  'suggestions.products.notFound': 'No products found',
  'suggestions.history.title': 'History',
  'suggestions.history.empty': 'No search found',
  'suggestions.topSearches.title': 'Most searched terms',
  'suggestions.topSearches.empty': 'No terms found',
}
