/* eslint-disable no-useless-escape */
export const updateOrderFormRepresentantId = async (
  orderFormId: string,
  marketingData: any,
  representantId?: string
) => {
  const headers = new Headers()

  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json')

  marketingData.utmiPart =
    representantId
      ?.replace(/[^\d]+/g, '')
      .replace(/[\[\].!'@,><|://\\;&*()_+=]/g, '')
      .substring(0, 8) ?? '0'

  return fetch(
    `/api/checkout/pub/orderForm/${orderFormId}/attachments/marketingData`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        ...marketingData,
      }),
    }
  )
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      return res
    })
    .catch((error) => {
      console.error('Error to update orderForm', error)
    })
}
