import type { OrderFormFragment_OrderFormFragment } from '@vtex/gatsby-theme-store/src/sdk/orderForm/controller/__generated__/OrderFormFragment_orderForm.graphql'
import type { PossibleOrderForms } from '@vtex/order-manager/types/typings'

export const shippingCalculate = async (
  postalCode: string,
  orderForm: PossibleOrderForms<OrderFormFragment_OrderFormFragment>,
  channel: string | null
) => {
  const myHeaders = new Headers()

  myHeaders.append('Content-Type', 'application/json')

  const items = orderForm.items.map((item) => ({
    id: item.id,
    quantity: item.quantity,
    seller: '1',
  }))

  const body = JSON.stringify({
    postalCode,
    country: 'BRA',
    items,
  })

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body,
  }

  return fetch(
    `/api/checkout/pub/orderForms/simulation?sc=${channel}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((response) => {
      return response.logisticsInfo
    })
    .catch((error) => console.error('Error while fetch data.', error))
}
