import type { FC } from 'react'
import React, { useMemo, useState, createContext } from 'react'

export interface IContext {
  isLoginModalOpen: boolean
  setIsLoginModalOpen: (value: boolean) => void
}

export const LoginModalContext = createContext<IContext | undefined>(undefined)

export const LoginModalProvider: FC = ({ children }) => {
  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false)

  const value = useMemo(
    () => ({
      isLoginModalOpen,
      setIsLoginModalOpen: (val: boolean) => {
        setIsLoginModalOpen(val)
      },
    }),
    [isLoginModalOpen]
  )

  return (
    <LoginModalContext.Provider value={value}>
      {children}
    </LoginModalContext.Provider>
  )
}
