import { masterData } from '@vtex/gatsby-theme-store'

export const searchDocumentService = async (userId: string) => {
  return fetch(
    `/api/dataentities/WL/search?_where=(userId=${userId})&_fields=userId,productsIds,id`
  )
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      const wishlist = res[0]?.productsIds
      const docId = res[0]?.id

      return { wishlist, docId }
    })
}

export const searchSkusService = async (query: string) => {
  return fetch(`/api/catalog_system/pub/products/search/?${query}`).then(
    (res) => {
      return res.json()
    }
  )
}

export const searchProductsService = async (query: string) => {
  return fetch(`/api/catalog_system/pub/products/search/?${query}`)
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      const products = res

      return products
    })
}

export const authService = async () => {
  return fetch('/api/vtexid/pub/authenticated/user')
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      const session = res

      return session
    })
}

const md = masterData('WL')

export const upsertDocumentService = async (
  userId: string,
  productsIds: string[],
  id?: string[]
) => {
  const response = await md.upsert({
    userId,
    productsIds,
    id: id?.length ? id[0] : id,
  })

  return response
}

export const getWishlistWithSafeDataService = async (
  userDocumentId: string
) => {
  return fetch(
    `/api/io/safedata/CL/documents/${userDocumentId}?_fields=id,wishlistProducts`
  )
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      const wishlist = res.wishlistProducts

      return wishlist
    })
}

export const searchUserDocumentIdByUserEmailService = async (
  userEmail: string
) => {
  return fetch(
    `/api/io/safedata/CL/search?_where=email=${userEmail}&_fields=id`
  )
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      return res[0].id
    })
}

export const upsertWishlistWithSafeDataService = async (
  userDocumentId: string,
  productsIds: string[]
) => {
  const headers = new Headers()

  headers.append('Content-Type', 'application/json')

  return fetch(`/api/io/safedata/CL/documents/${userDocumentId}`, {
    method: 'PATCH',
    headers,
    body: JSON.stringify({
      wishlistProducts: productsIds,
    }),
  })
    .then((res) => {
      return res.json()
    })
    .then((res) => {
      return res
    })
}
