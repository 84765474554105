import type { FC } from 'react'
import React, { useMemo, useState, createContext } from 'react'

export interface IContext {
  isCollaboratorStore: boolean
  setIsCollaboratorStore: (value: boolean) => void
}

export const StoreTypeContext = createContext<IContext | undefined>(undefined)

export const StoreTypeProvider: FC = ({ children }) => {
  const [isCollaboratorStore, setIsCollaboratorStore] = useState<boolean>(false)

  const value = useMemo(
    () => ({
      isCollaboratorStore,
      setIsCollaboratorStore: (val: boolean) => {
        setIsCollaboratorStore(val)
      },
    }),
    [isCollaboratorStore]
  )

  return (
    <StoreTypeContext.Provider value={value}>
      {children}
    </StoreTypeContext.Provider>
  )
}
