import { useContext } from 'react'

import { LoginModalContext } from './index'

export const useLoginModal = () => {
  const context = useContext(LoginModalContext)

  if (context === undefined) {
    throw new Error('Region context provider missing from React tree')
  }

  return context
}
