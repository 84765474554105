import { createTheme, infoCardTheme, searchControlsTheme } from '@vtex/store-ui'

import authTheme from './auth'
import { base } from './base'
import { breadcrumb } from './breadcrumb'
import { custom } from './custom'
import offerTheme from './offer'
import productDetailsTheme from './productDetails'
import { productQuantityTheme } from './productQuantity'
import searchBarTheme from './searchBar'
import { searchTheme } from './searchFilter'
import searchSuggestionsTheme from './searchSuggestions'
import shelfTheme from './shelf'
import loginTheme from './login'
import minicartTheme from './minicart'
import toastTheme from './toast'
import { newsletter } from './newsletter'
import { mosaic } from './mosaic'
import { footer } from './footer'
import { menu } from './menu'
import { header } from './header'

export default createTheme(
  base,
  breadcrumb,
  minicartTheme,
  infoCardTheme,
  productQuantityTheme,
  searchControlsTheme,
  searchSuggestionsTheme,
  searchBarTheme,
  searchTheme,
  custom,
  offerTheme,
  productDetailsTheme,
  loginTheme,
  authTheme,
  toastTheme,
  shelfTheme,
  newsletter,
  mosaic,
  header,
  menu,
  footer
)
