// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-[slug]-p-tsx": () => import("./../../../src/pages/[slug]/p.tsx" /* webpackChunkName: "component---src-pages-[slug]-p-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-angela-index-tsx": () => import("./../../../src/pages/angela/index.tsx" /* webpackChunkName: "component---src-pages-angela-index-tsx" */),
  "component---src-pages-avon-tratamake-index-tsx": () => import("./../../../src/pages/avon-tratamake/index.tsx" /* webpackChunkName: "component---src-pages-avon-tratamake-index-tsx" */),
  "component---src-pages-bases-color-index-tsx": () => import("./../../../src/pages/bases-color/index.tsx" /* webpackChunkName: "component---src-pages-bases-color-index-tsx" */),
  "component---src-pages-batons-ultras-index-tsx": () => import("./../../../src/pages/batons-ultras/index.tsx" /* webpackChunkName: "component---src-pages-batons-ultras-index-tsx" */),
  "component---src-pages-black-friday-avon-index-tsx": () => import("./../../../src/pages/black-friday-avon/index.tsx" /* webpackChunkName: "component---src-pages-black-friday-avon-index-tsx" */),
  "component---src-pages-cms-pilar-page-post-configuration-post-configuration-seo-slug-index-tsx": () => import("./../../../src/pages/{CmsPilarPage.postConfiguration__postConfiguration__seo__slug}/index.tsx" /* webpackChunkName: "component---src-pages-cms-pilar-page-post-configuration-post-configuration-seo-slug-index-tsx" */),
  "component---src-pages-cms-pl-landing-page-parameters-pl-landing-page-params-seo-slug-index-tsx": () => import("./../../../src/pages/{CmsPlLandingPage.parameters__plLandingPageParams__seo__slug}/index.tsx" /* webpackChunkName: "component---src-pages-cms-pl-landing-page-parameters-pl-landing-page-params-seo-slug-index-tsx" */),
  "component---src-pages-cms-pl-landing-page-parameters-pl-landing-page-params-seo-slug-tsx": () => import("./../../../src/pages/{CmsPlLandingPage.parameters__plLandingPageParams__seo__slug}/[...].tsx" /* webpackChunkName: "component---src-pages-cms-pl-landing-page-parameters-pl-landing-page-params-seo-slug-tsx" */),
  "component---src-pages-colaborador-index-tsx": () => import("./../../../src/pages/colaborador/index.tsx" /* webpackChunkName: "component---src-pages-colaborador-index-tsx" */),
  "component---src-pages-colecao-nudes-index-tsx": () => import("./../../../src/pages/colecao-nudes/index.tsx" /* webpackChunkName: "component---src-pages-colecao-nudes-index-tsx" */),
  "component---src-pages-color-trend-index-tsx": () => import("./../../../src/pages/color-trend/index.tsx" /* webpackChunkName: "component---src-pages-color-trend-index-tsx" */),
  "component---src-pages-cupom-de-desconto-index-tsx": () => import("./../../../src/pages/cupom-de-desconto/index.tsx" /* webpackChunkName: "component---src-pages-cupom-de-desconto-index-tsx" */),
  "component---src-pages-cyber-monday-avon-index-tsx": () => import("./../../../src/pages/cyber-monday-avon/index.tsx" /* webpackChunkName: "component---src-pages-cyber-monday-avon-index-tsx" */),
  "component---src-pages-encontresuacor-index-tsx": () => import("./../../../src/pages/encontresuacor/index.tsx" /* webpackChunkName: "component---src-pages-encontresuacor-index-tsx" */),
  "component---src-pages-encontresuacor-resultado-index-tsx": () => import("./../../../src/pages/encontresuacor/resultado/index.tsx" /* webpackChunkName: "component---src-pages-encontresuacor-resultado-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-institucional-seja-representante-cadastro-index-tsx": () => import("./../../../src/pages/institucional/seja-representante/cadastro/index.tsx" /* webpackChunkName: "component---src-pages-institucional-seja-representante-cadastro-index-tsx" */),
  "component---src-pages-institucional-seja-representante-index-tsx": () => import("./../../../src/pages/institucional/seja-representante/index.tsx" /* webpackChunkName: "component---src-pages-institucional-seja-representante-index-tsx" */),
  "component---src-pages-liguecancer-index-tsx": () => import("./../../../src/pages/liguecancer/index.tsx" /* webpackChunkName: "component---src-pages-liguecancer-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-mascara-legendary-index-tsx": () => import("./../../../src/pages/mascara-legendary/index.tsx" /* webpackChunkName: "component---src-pages-mascara-legendary-index-tsx" */),
  "component---src-pages-natal-avon-index-tsx": () => import("./../../../src/pages/natal-avon/index.tsx" /* webpackChunkName: "component---src-pages-natal-avon-index-tsx" */),
  "component---src-pages-quiz-antissinais-index-tsx": () => import("./../../../src/pages/quiz-antissinais/index.tsx" /* webpackChunkName: "component---src-pages-quiz-antissinais-index-tsx" */),
  "component---src-pages-quiz-batons-index-tsx": () => import("./../../../src/pages/quiz-batons/index.tsx" /* webpackChunkName: "component---src-pages-quiz-batons-index-tsx" */),
  "component---src-pages-quiz-pele-index-tsx": () => import("./../../../src/pages/quiz-pele/index.tsx" /* webpackChunkName: "component---src-pages-quiz-pele-index-tsx" */),
  "component---src-pages-quiz-pele-resultado-index-tsx": () => import("./../../../src/pages/quiz-pele/resultado/index.tsx" /* webpackChunkName: "component---src-pages-quiz-pele-resultado-index-tsx" */),
  "component---src-pages-quiz-perfume-[gender]-index-tsx": () => import("./../../../src/pages/quiz-perfume/[gender]/index.tsx" /* webpackChunkName: "component---src-pages-quiz-perfume-[gender]-index-tsx" */),
  "component---src-pages-quiz-perfume-[gender]-resultado-tsx": () => import("./../../../src/pages/quiz-perfume/[gender]/resultado.tsx" /* webpackChunkName: "component---src-pages-quiz-perfume-[gender]-resultado-tsx" */),
  "component---src-pages-quiz-perfume-index-tsx": () => import("./../../../src/pages/quiz-perfume/index.tsx" /* webpackChunkName: "component---src-pages-quiz-perfume-index-tsx" */),
  "component---src-pages-s-tsx": () => import("./../../../src/pages/s/[...].tsx" /* webpackChunkName: "component---src-pages-s-tsx" */),
  "component---src-pages-store-collection-slug-index-tsx": () => import("./../../../src/pages/{StoreCollection.slug}/index.tsx" /* webpackChunkName: "component---src-pages-store-collection-slug-index-tsx" */),
  "component---src-pages-store-collection-slug-tsx": () => import("./../../../src/pages/{StoreCollection.slug}/[...].tsx" /* webpackChunkName: "component---src-pages-store-collection-slug-tsx" */),
  "component---src-pages-store-product-slug-p-tsx": () => import("./../../../src/pages/{StoreProduct.slug}/p.tsx" /* webpackChunkName: "component---src-pages-store-product-slug-p-tsx" */),
  "component---src-pages-wishlist-tsx": () => import("./../../../src/pages/wishlist.tsx" /* webpackChunkName: "component---src-pages-wishlist-tsx" */)
}

