module.exports = [{
      plugin: () => require('../node_modules/@vtex/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: () => require('../node_modules/@vtex/gatsby-plugin-i18n/gatsby-browser.js'),
      options: {"plugins":[],"locales":["en","pt"],"defaultLocale":"pt"},
    },{
      plugin: () => require('../node_modules/@vtex/gatsby-theme-store/gatsby-browser.js'),
      options: {"plugins":[],"profiling":false},
    },{
      plugin: () => require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#f05395","showSpinner":false},
    },{
      plugin: () => require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Avon - Loja de Produtos de Beleza e Revenda de Cosméticos","short_name":"Avon","start_url":"/","icon":"static/assets/icon.png","background_color":"#f05395","theme_color":"#f05395","display":"standalone","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: () => require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"defer":true},
    },{
      plugin: () => require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: () => require('../node_modules/@vtex/gatsby-plugin-thumbor/gatsby-browser.js'),
      options: {"plugins":[],"server":"http://thumbor.vtex.internal","basePath":"/assets","sizes":["1280x614","1280x613","1920x920","2880x1380","3840x1840","360x540","360x539","480x719","480x718","720x1079","720x1078","1440x690","1680x805","360x238","360x237","480x317","720x476","720x475","250x250","360x360","480x480","720x720","1024x491","1024x490","1280x238","1280x237","1440x268","1440x267","1920x357","1920x356"]},
    },{
      plugin: () => require('../node_modules/@vtex/gatsby-plugin-google-tag-manager/gatsby-browser.js'),
      options: {"plugins":[],"gtmId":"GTM-TC9FPHB","dataLayerConfig":[]},
    },{
      plugin: () => require('../node_modules/gatsby-plugin-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: () => require('../node_modules/gatsby-plugin-better-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: () => require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
