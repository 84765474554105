import React from 'react'
import { Helmet } from 'react-helmet'

const HeadComponents = () => {
  return (
    <Helmet htmlAttributes={{ lang: 'pt-BR' }}>
      <script
        type="text/javascript"
        id=""
        src="https://www.oppuz.com/script/avongroup2.js"
        defer
      />
      {/* <script
        type="text/javascript"
        src="https://cdn.cookielaw.org/consent/b4af895d-b235-480b-bb42-936631950db8/OtAutoBlock.js"
      /> */}
      <script
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        type="text/javascript"
        charset="UTF-8"
        data-domain-script="b4af895d-b235-480b-bb42-936631950db8"
      />
      <script type="text/javascript">function OptanonWrapper() {}</script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            <style>
              .async-hide { opacity: 0 !important}
            </style>
            <script>
              (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};(a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;})(window,document.documentElement,'async-hide','dataLayer',4000,{'OPT-N5T7G7C':true});
            </script>
          `,
        }}
      />
      {/* Pinterest Pixel Base Code */}
      <script type="text/javascript">
        {`!function(e){if(!window.pintrk){window.pintrk = function () {
        window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
        n=window.pintrk;n.queue=[],n.version="3.0";var
        t=document.createElement("script");t.async=!0,t.src=e;var
        r=document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
        pintrk('load', '2613915999024');
        pintrk('page');`}
      </script>
      <noscript>
        {`<img
          height="1"
          width="1"
          style="display:none;"
          alt=""
          src="https://ct.pinterest.com/v3/?tid=2613915999024&noscript=1"
        />`}
      </noscript>
      {/* End Pinterest Pixel Base Code */}
    </Helmet>
  )
}

export default HeadComponents
