import React, { Fragment } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { SessionProvider } from '@vtex/store-sdk'
import { LikedPostsProvider } from 'src/hooks/useLikedPosts'

import { LoginModalProvider } from './src/contexts/LoginModalContext'
import { StoreTypeProvider } from './src/contexts/StoreTypeContext'
import { ShippingCalculateProvider } from './src/contexts/ShippingCalculateContext'
import { RepresentativeContextProvider } from './src/contexts/RepresentativeContext'
import { WishlistProvider } from './src/contexts/WishlistContext'
import { NotifyMeModalProvider } from './src/contexts/NotifyMeModalContext'
import HeadComponents from './src/components/headComponents'

import './global.css'

export const wrapPageElement = ({ element, props: { location, data } }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf__bwUAAAAAAcD3DIJoQVhv8uMTkP08A32pHXW">
      <SessionProvider
        initialState={{
          channel: process.env.GATSBY_DEFAULT_CHANNEL,
        }}
      >
        <LoginModalProvider>
          <StoreTypeProvider>
            <ShippingCalculateProvider>
              <RepresentativeContextProvider search={location.search}>
                <WishlistProvider>
                  <NotifyMeModalProvider>
                    {data &&
                    Object.prototype.hasOwnProperty.call(
                      data,
                      'cmsPilarPage'
                    ) ? (
                      <LikedPostsProvider
                        postTitle={
                          // eslint-disable-next-line prettier/prettier
                          data.cmsPilarPage.postConfiguration.postConfiguration
                            .title
                        }
                      >
                        <HeadComponents />
                        {element}
                      </LikedPostsProvider>
                    ) : (
                      <Fragment>
                        <HeadComponents />
                        {element}
                      </Fragment>
                    )}
                  </NotifyMeModalProvider>
                </WishlistProvider>
              </RepresentativeContextProvider>
            </ShippingCalculateProvider>
          </StoreTypeProvider>
        </LoginModalProvider>
      </SessionProvider>
    </GoogleReCaptchaProvider>
  )
}
