const APP_NAME = 'STORE'

export function getStorageItem(key: string) {
  if (typeof window === 'undefined') {
    return null
  }

  const data = window.localStorage.getItem(`${APP_NAME}_${key}`)

  if (data === null) {
    return null
  }

  return JSON.parse(data)
}

export function setStorageItem(key: string, value: string[]) {
  if (typeof window === 'undefined') {
    return null
  }

  const data = JSON.stringify(value)

  return window.localStorage.setItem(`${APP_NAME}_${key}`, data)
}
