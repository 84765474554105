/* eslint-disable react-hooks/exhaustive-deps */
import { localizedPath, useIntl } from '@vtex/gatsby-plugin-i18n'
import { navigate } from 'gatsby'
import React, { useState, createContext, useEffect } from 'react'
import {
  authService,
  getWishlistWithSafeDataService,
  searchUserDocumentIdByUserEmailService,
  upsertWishlistWithSafeDataService,
} from 'src/services/wishlist'
import { getStorageItem, setStorageItem } from 'src/utils/storageItem'

const WISHLIST_KEY = 'wishlist'

export type WishlistContextData = {
  items: string[]
  isInWishlist: (id: string) => boolean
  addToWishlist: (id: string) => void
  removeFromWishlist: (id: string) => void
  loading: boolean
}

export const WishlistContextDefaultValues = {
  items: [],
  isInWishlist: () => false,
  addToWishlist: () => null,
  removeFromWishlist: () => null,
  loading: false,
}

export const WishlistContext = createContext<WishlistContextData>(
  WishlistContextDefaultValues
)

export type WishlistProviderProps = {
  children: React.ReactNode
}

export type ISession = {
  user: string
  userId: string
  userType: string
}

export const WishlistProvider = ({ children }: WishlistProviderProps) => {
  const [wishlistItems, setWishlistItems] = useState<string[]>([])
  const [loadingWishlist, setLoadingWishlist] = useState(false)
  const [session, setSession] = useState<ISession | null>(null)
  const { locale, defaultLocale } = useIntl()

  const getAuth = async () => {
    const response = await authService()

    setSession(response)
  }

  const formatWishlist = (unformattedWishlist: any): any => {
    // some users have their wishlist registered as string like "[187736605,138493667,115630236]"
    if (typeof unformattedWishlist === 'string') {
      try {
        unformattedWishlist = JSON.parse(unformattedWishlist)
        // map items to String to match productId
        unformattedWishlist = unformattedWishlist.map(String)
      } catch {
        unformattedWishlist = null
      }
    }

    return unformattedWishlist
  }

  const getWishlistIds = async () => {
    setLoadingWishlist(true)
    if (session) {
      const localWishlist = getStorageItem(WISHLIST_KEY)

      if (localWishlist) {
        // some users have their wishlist registered as string like "[187736605,138493667,115630236]"
        setWishlistItems(formatWishlist(localWishlist))
      } else {
        const userDocumentId = await searchUserDocumentIdByUserEmailService(
          session.user
        )

        let wishlist = await getWishlistWithSafeDataService(userDocumentId)

        // some users have their wishlist registered as string like "[187736605,138493667,115630236]"
        wishlist = formatWishlist(wishlist)

        if (wishlist) {
          setWishlistItems(wishlist)
          setStorageItem(WISHLIST_KEY, wishlist)
        } else {
          setWishlistItems([])
        }
      }
    }

    setLoadingWishlist(false)
  }

  useEffect(() => {
    getAuth()
    getWishlistIds()
  }, [])

  useEffect(() => {
    getWishlistIds()
  }, [session])

  const isInWishlist = (id: string) => {
    if (wishlistItems?.length) {
      return wishlistItems?.some((item: string) => item === id)
    }

    return false
  }

  const addToWishlist = async (id: string) => {
    if (session) {
      const itens = [...wishlistItems]

      itens.filter((item: string) => item !== id)
      itens.push(id)

      setWishlistItems([...wishlistItems, id])
      setStorageItem(WISHLIST_KEY, itens)

      const userDocumentId = await searchUserDocumentIdByUserEmailService(
        session.user
      )

      await upsertWishlistWithSafeDataService(userDocumentId, itens)
    } else {
      const path = localizedPath(defaultLocale, locale, '/login')

      navigate(path)

      return
    }

    return null
  }

  const removeFromWishlist = async (id: string) => {
    if (session) {
      const itens = wishlistItems.filter((item: string) => item !== id)

      setWishlistItems(itens)
      setStorageItem(WISHLIST_KEY, itens)

      const userDocumentId = await searchUserDocumentIdByUserEmailService(
        session.user
      )

      await upsertWishlistWithSafeDataService(userDocumentId, itens)
    }

    return null
  }

  return (
    <WishlistContext.Provider
      value={{
        items: wishlistItems,
        isInWishlist,
        addToWishlist,
        removeFromWishlist,
        loading: loadingWishlist,
      }}
    >
      {children}
    </WishlistContext.Provider>
  )
}
