import { authProvidersI18nPT, minicartI18nPT } from '@vtex/store-ui'

export default {
  ...minicartI18nPT,

  ...authProvidersI18nPT,

  'minicart.drawer.shipping-disclaimer': '',
  'minicart.drawer.go-checkout': 'Fechar pedido',
  'minicart.drawer.count': 'Seu carrinho',
  'minicart.drawer.keep-buying': 'Continuar comprando',

  'login.button.greeting': 'Olá, {name}',
  'login.button.action': 'Entrar',
  'login.button.actionMobile': 'Entre ou cadastre-se',
  'login.page.emailAndPassword.signInForm.title': 'Entrar com email e senha',
  'login.page.emailAndPassword.signInForm.emailLabel': 'Seu email',
  'login.page.emailAndPassword.signInForm.emailPlaceholder':
    'Ex: jose@email.com',
  'login.page.emailAndPassword.signInForm.signupButton':
    'Não tem uma senha? Cadastre agora',
  'login.page.emailAndPassword.signInForm.enterWithCode':
    'Receba o código de acesso por email',
  'login.page.emailAndPassword.emailForm.title': 'Por favor informe seu email',
  'login.page.emailVerification.emailForm.title': 'Por favor informe seu email',
  'login.page.emailAndPassword.emailForm.invalidEmailInput': 'Email inválido',
  'login.page.emailAndPassword.signUpForm.subTitle':
    'Enviamos um código para {email}',
  'login.page.emailAndPassword.signUpForm.submitButton': 'CADASTRAR NOVA SENHA',
  'login.pageText.emailAndPassword.emailForm.error':
    'Algo de errado aconteceu, tente novamente',
  'login.page.emailAndPassword.signUpForm.title': 'Cadastrar nova senha',
  'login.page.emailVerification.accessCodeForm.title':
    'Informar chave de acesso',

  'shelf.title.0': 'Ofertas',

  'social.share': 'Compartilhar',

  'offer.soldout': 'Produto indisponível',
  'offer.product-unavailable': 'Product indisponivel',
  'offer.units-left': '{quantity} unidades!',
  'offer.installments.bold': 'Em até',
  'offer.installments': ' {numberOfInstallments}X sem juros ',
  'offer.discount': ' Economize {price}',
  'offer.product.installments': 'Parcele em {numberOfInstallments}x de {value}',
  'offer.product.listprice': 'De {listPrice}',

  'productDetails.reference': 'Referencia',
  'product-not-found': 'Product não encontrado',
  'error-generic': 'Erro',

  'buy-button.add-to-cart': 'Adicionar ao carrinho',
  'buy-button.add-to-cart.bbb': 'Adicionar ao carrinho',

  'facets.filters': 'Filtros',

  loading: 'Carregando...',

  'notification-bar.sale': 'LOJA AVON',

  'product.quantity.title': 'Quantidade',

  'searchControls.totalCount':
    'Temos {count} {count,plural,=0{ itens para você}one{ item para você}other{ itens para você}}',
  'searchControls.filters': 'Filtros',
  'searchControls.select.scoreDesc': 'Relevância',
  'searchControls.select.priceDesc': 'Maior preço',
  'searchControls.select.priceAsc': 'Menor preço',
  'searchControls.select.topSaleDesc': 'Mais vendidos',
  'searchControls.select.reviewRateDesc': 'Reviews',
  'searchControls.select.nameDesc': 'Z - A',
  'searchControls.select.nameAsc': 'A - Z',
  'searchControls.select.releaseDateDesc': 'Data de lançamento',
  'searchControls.select.bestDiscountDesc': 'Melhor desconto',

  'error.title': 'Ops, ocorreu um erro!',
  'error.action': 'Por favor, volte ao início e tente novamente',
  'error.action.button': 'Voltar ao início',
  'error.detail': 'Erro: {errorId}',

  'advantageBar.label1.regular': 'Enviamos para ',
  'advantageBar.label1.bold': 'todo Brasil',
  'advantageBar.label2':
    'Parcelamento em até 6x sem juros - Parcela mínima R$10,00',

  'departmentMenu.label.item0': 'Best Friday',
  'departmentMenu.label.item1': 'Kits e Presentes',
  'departmentMenu.label.item2': 'Maquiagem',
  'departmentMenu.label.item3': 'Rosto',
  'departmentMenu.label.item4': 'Corpo e Banho',
  'departmentMenu.label.item5': 'Perfumaria',

  'suggestions.autocomplete.title': 'Sugestões de busca',
  'suggestions.autocomplete.notFound': 'Sem sugestões',
  'suggestions.products.title': 'Produtos sugeridos',
  'suggestions.products.total': 'Ver todos os {total} itens',
  'suggestions.products.notFound': 'Nenhum produto encontrado',
  'suggestions.history.title': 'Historico',
  'suggestions.history.empty': 'Nenhuma pesquisa encontrada',
  'suggestions.topSearches.title': 'Termos mais buscados',
  'suggestions.topSearches.empty': 'Nenhum termo encontrado',
}
