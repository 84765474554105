import React, { useState, createContext, useContext, useEffect } from 'react'
import {
  authService,
  getLikedPoststWithSafeDataService,
  searchUserDocumentIdByUserEmailService,
  upsertLikedPostsWithSafeDataService,
} from 'src/services/likedPosts'
import { getStorageItem, setStorageItem } from 'src/utils/storageItem'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'

const LIKEDPOSTS_KEY = 'likedPosts'

export type LikedPostsContextData = {
  items: string[]
  getAuthentication: () => void
  isInLikedPosts: (postTitle: string) => boolean
  addToLikedPosts: (postTitle: string) => void
  removeFromLikedPosts: (postTitle: string) => void
}

export const LikedPostsContextDefaultValues = {
  items: [],
  getAuthentication: () => null,
  isInLikedPosts: () => false,
  addToLikedPosts: () => null,
  removeFromLikedPosts: () => null,
}

export const LikedPostsContext = createContext<LikedPostsContextData>(
  LikedPostsContextDefaultValues
)

export type LikedPostsProviderProps = {
  children: React.ReactNode
  postTitle: string
}

export type ISession = {
  user: string
  userId: string
  userType: string
}

const useLikedPosts = () => useContext(LikedPostsContext)

const LikedPostsProvider = ({
  children,
  postTitle,
}: LikedPostsProviderProps) => {
  const [session, setSession] = useState<ISession | null>(null)
  const [likedPostsItems, setLikedPostsItems] = useState<string[]>([])
  const { setIsLoginModalOpen } = useLoginModal()

  const formatLikedPosts = (unformattedLikedPosts: any): any => {
    if (typeof unformattedLikedPosts === 'string') {
      try {
        unformattedLikedPosts = JSON.parse(unformattedLikedPosts)
        unformattedLikedPosts = unformattedLikedPosts.map(String)
      } catch {
        unformattedLikedPosts = null
      }
    }

    return unformattedLikedPosts
  }

  const getAuthentication = async () => {
    try {
      const response = await authService()

      if (!response) {
        return setIsLoginModalOpen(true)
      }

      setSession(response)
    } catch (error) {
      console.error('Error while getting user data.', error)
    }
  }

  const getLikedPosts = async () => {
    if (session?.user) {
      const localLikedPosts = getStorageItem(LIKEDPOSTS_KEY)

      if (localLikedPosts) {
        setLikedPostsItems(formatLikedPosts(localLikedPosts))
      } else {
        const userDocumentId = await searchUserDocumentIdByUserEmailService(
          session.user
        )

        let likedPosts = await getLikedPoststWithSafeDataService(userDocumentId)

        likedPosts = formatLikedPosts(likedPosts)

        if (likedPosts) {
          setLikedPostsItems(likedPosts)
          setStorageItem(LIKEDPOSTS_KEY, likedPosts)
        } else {
          setLikedPostsItems([])
        }

        return likedPosts
      }
    }
  }

  const isInLikedPosts = () => {
    if (likedPostsItems?.length) {
      return likedPostsItems?.some((item: string) => item === postTitle)
    }

    return false
  }

  const addToLikedPosts = async () => {
    if (session?.user) {
      const items = [...likedPostsItems]

      items.filter((item: string) => item !== postTitle)
      items.push(postTitle)

      setLikedPostsItems([...likedPostsItems, postTitle])
      setStorageItem(LIKEDPOSTS_KEY, items)

      const userDocumentId = await searchUserDocumentIdByUserEmailService(
        session.user
      )

      await upsertLikedPostsWithSafeDataService(userDocumentId, items)
    } else {
      return setIsLoginModalOpen(true)
    }

    return null
  }

  const removeFromLikedPosts = async () => {
    if (session?.user) {
      const items = likedPostsItems.filter((item: string) => item !== postTitle)

      setLikedPostsItems(items)
      setStorageItem(LIKEDPOSTS_KEY, items)

      const userDocumentId = await searchUserDocumentIdByUserEmailService(
        session.user
      )

      await upsertLikedPostsWithSafeDataService(userDocumentId, items)
    }

    return null
  }

  useEffect(() => {
    getAuthentication()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getLikedPosts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session])

  return (
    <LikedPostsContext.Provider
      value={{
        items: likedPostsItems,
        getAuthentication,
        isInLikedPosts,
        addToLikedPosts,
        removeFromLikedPosts,
      }}
    >
      {children}
    </LikedPostsContext.Provider>
  )
}

export { LikedPostsProvider, useLikedPosts }
